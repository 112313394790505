<template>
  <template v-if="schema.enum">
      <select :id="name" class="form-select" v-model="value">
        <option value=""></option>
        <option v-for="opt in schema.enum" :key="opt" :value="opt" v-text="opt"></option>
      </select>
  </template>
  <input v-else-if="schema.format === 'date-time'" type="datetime-local" class="form-control" :id="name" v-model="value">
  <input v-else-if="name === 'date'" type="date" class="form-control" :id="name" v-model="value">
  <input v-else type="text" class="form-control" :id="name" v-model="value">
</template>
<script>
export default {
  name: 'ParameterInput',
  props: ['schema', 'name', 'modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  created() {
    if(this.name === 'dateRange' && !this.modelValue) {
      this.$emit('update:modelValue', '7d')
    }
  }
}
</script>
