<template>
  <div class="col-3 mb-2" v-for="(option, index) in options" :key="index">
    <div class="form-group">
      <select class="form-select" :value="selections[index]" @input.prevent="changeSelect($event.target.value, index)">
        <option v-for="opt in option" :key="opt" :value="opt" v-text="opt"></option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EndpointPicker',
  props: {
    endpoints: {},
    modelValue: {},
    series: {},
  },
  emits: ['firstEndpoint', 'update:modelValue'],
  data: function () {
    return {
      selections: [],
      options: [],
    }
  },
  methods: {
    async changeSelect(value, index) {
      this.selections[index] = value
      this.selections = this.selections.slice(0, index + 1)

      const params = this.$route.query.parameters
      if (params === {} || !params || Object.keys(params).length === 0) {
        await this.$router.push({name: 'home', query: {endpoint: this.selections.join('/'), series: this.series}})
      } else {
        await this.$router.push({name: 'home', query: {endpoint: this.selections.join('/'), series: this.series, parameters: params}})
      }
      this.updateOptions()
    },
    updateOptions() {
      this.options = this.options.slice(0, this.selections.length)

      const currentUrl = this.selections.join('/')

      const newOptions = []
      for (const url of Object.keys(this.endpoints)) {
        // the 2º filter is an hack to exclude timeseries_groups from timeseries picks
        if (!url.startsWith(currentUrl) || url.startsWith(`${currentUrl}_`)) {
          continue
        }

        const parts = url.split('/')

        if (parts[this.selections.length] && !newOptions.includes(parts[this.selections.length]))
          newOptions.push(parts[this.selections.length])
      }

      if (newOptions.length > 0) {
        this.options[this.selections.length] = newOptions
        this.$emit('update:modelValue', null)
      } else {
        this.$emit('update:modelValue', this.endpoints[currentUrl])
        // console.log(this.endpoints[currentUrl])
      }
    },
  },
  async mounted() {
    this.updateOptions()

    if (this.$route.query.endpoint) {
      for (const [index, path] of this.$route.query.endpoint.split('/').entries()) {
        await this.changeSelect(path, index)
      }

    } else {
      await this.changeSelect('netflows', 0)
      await this.changeSelect('timeseries', 1)
    }

    await this.$nextTick()
    this.$emit('firstEndpoint')
  }
}
</script>
