<template>
  <div class="form-group">
    <label :for="parameter.name" class="col-sm-2 col-form-label d-block w-100">{{ parameter.name }}
      <div v-if="parameter.name === 'location'" class="tooltip-r"><i class="bi bi-question-circle"></i>
        <span class="tooltiptext-r">Alpha2, comma separated</span>
      </div>
      <div v-else-if="isCommaSeparated" class="tooltip-r"><i class="bi bi-question-circle"></i>
        <span class="tooltiptext-r">Can be comma separated</span>
      </div>
    </label>
    <div class="col-sm-10">
      <template v-if="isArray">
        <template v-for="(item, index) in series" :key="index">
          <ParameterInput class="mb-2" :name="parameter.name" :schema="parameter.schema.items"
                          :model-value="value[index]"
                          @update:modelValue="newValue => this.$emit('update:modelValue', {
                            ...value, [index]: newValue
                          })"/>
        </template>
      </template>
      <ParameterInput v-else :name="parameter.name" :schema="parameter.schema" v-model:modelValue="value"/>
    </div>
  </div>
</template>
<script>
import ParameterInput from "@/components/ParameterInput.vue";

export default {
  name: 'EndpointParameter',
  components: {ParameterInput},
  props: ['parameter', 'series', 'modelValue'],
  watch: {
    series(newValue, oldValue) {
      const index = newValue - 1

      if (this.isArray && newValue < oldValue) {
        const tmpValue = {...this.value}
        for (const key of Object.keys(tmpValue)) {
          if (key > index) {
            delete tmpValue[key]
          }
        }

        this.value = tmpValue
      }
    }
  },
  computed: {
    isCommaSeparated() {
      return [
          'location',
          'asn',
          'locations',
          'asns',
          'domains',
      ].includes(this.parameter.name)
    },
    isArray() {
      return this.parameter.schema.type === 'array'
    },
    value: {
      get() {
        if (this.isArray) {
          if (!this.modelValue) {
            return {}
          }

          return this.modelValue
        }
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
