<template>
  <timeseries-chart v-if="endpoint.chartType === 'timeseries'" :data="data"/>
  <timeseries-groups-chart v-else-if="endpoint.chartType === 'timeseries_groups'" :data="data"/>
  <summary-chart v-else-if="endpoint.chartType === 'summary'" :data="data"/>
  <top-chart v-else-if="endpoint.chartType === 'top'" :data="data"/>
  <table-chart v-else-if="endpoint.chartType === 'table'" :data="data"/>
  <pew-pew-chart v-else-if="endpoint.chartType === 'pewpew'" :data="data"/>
</template>
<script>
import TimeseriesChart from "@/components/charts/TimeseriesChart.vue";
import TimeseriesGroupsChart from "@/components/charts/TimeseriesGroupsChart.vue";
import SummaryChart from "@/components/charts/SummaryChart.vue";
import TopChart from "@/components/charts/TopChart.vue";
import TableChart from "@/components/charts/TableChart.vue";
import PewPewChart from "@/components/charts/PewPewChart.vue";

export default {
  name: 'ChartWrapper',
  components: {TableChart, TopChart, SummaryChart, TimeseriesGroupsChart, TimeseriesChart, PewPewChart},
  props: ['endpoint', 'data']
}
</script>
