<template>
  <div class="row">
    <div class="col-6" v-for="(data, index) of chartData" :key="index">
      <Bar
          :options="chartOptions"
          :data="data"
      />
    </div>
  </div>
</template>
<script>
import {Bar} from 'vue-chartjs'

export default {
  name: 'TopChart',
  components: {Bar},
  props: ['data'],
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  computed: {
    chartData() {
      const result = []
      console.log(this.data)

      for (const [key, value] of Object.entries(this.data)) {

        let labels = []
        let data = []

        for (const line of value) {
          if (line.clientCountryName) {
            labels.push(line.clientCountryName)
          } else if (line.clientASName) {
            labels.push(line.clientASName)
          } else if (line.name) {
            labels.push(line.name)
          } else {
            for (const key of Object.keys(line)) {
              if (key !== 'value' && key !== 'rank') {
                labels.push(line[key])
                break
              }
            }
          }

          data.push(line.value || line.rank)
        }

        result.push({
          labels: labels,
          datasets: [
            {
              label: key,
              data: data
            }
          ]
        })
      }

      return result
    }
  },
}
</script>
