<template>
  <div class="row">
    <div class="col-12" v-for="(data, index) of chartData" :key="index">
      <Sankey
          :data="data"
      />
    </div>
  </div>
</template>
<script>
import {createTypedChart} from 'vue-chartjs'
import {SankeyController} from 'chartjs-chart-sankey';

const Sankey = createTypedChart('sankey', SankeyController)

export default {
  name: 'PewPewChart',
  components: {Sankey},
  props: ['data'],
  data() {
    return {}
  },
  computed: {
    chartOptions() {
      return Object.keys(this.data).map(val => {
        return {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: val
            }
          }
        }
      })
    },
    chartData() {
      const result = []

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.data)) {
        const data = value.map((obj) => {
          return {from: obj.originCountryAlpha2+'_origin', from_name: obj.originCountryName, to: obj.targetCountryAlpha2+'_target', to_name: obj.targetCountryName, flow: parseFloat(obj.value)}
        })

        const labels = {}
        for (const val of data) {
          if (!labels[val.from]) {
            labels[val.from] = val.from_name
          }
          if (!labels[val.to]) {
            labels[val.to] = val.to_name
          }
        }

        result.push(
            {
              datasets: [{
                data: data,
                labels: labels,
                size: 'max', // or 'min' if flow overlap is preferred
              }]
            }
        )

      }
      return result
    }
  },
}
</script>
