<template>
  <div class="row">
    <div class="col-6" v-for="(data, index) of chartData" :key="index">
      <Doughnut
          :options="chartOptions[index]"
          :data="data"
      />
    </div>
  </div>
</template>
<script>
import {Doughnut} from 'vue-chartjs'

export default {
  name: 'SummaryChart',
  components: {Doughnut},
  props: ['data'],
  data() {
    return {}
  },
  computed: {
    chartOptions() {
      return Object.keys(this.data).map(val => {
        return {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: val
            }
          }
        }
      })
    },
    chartData() {
      const result = []

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.data)) {

        let labels = []
        let data = []

        for (const [key2, value2] of Object.entries(value)) {
          labels.push(key2)
          data.push(value2)
        }

        result.push({
          labels: labels,
          datasets: [
            {
              data: data
            }
          ]
        })

      }
      return result
    }
  }
}
</script>
