<template>
  <Line
      :options="chartOptions"
      :data="chartData"
  />
</template>
<script>
import {Line} from 'vue-chartjs'

export default {
  name: 'TimeseriesChart',
  components: {Line},
  props: ['data'],
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  computed: {
    chartData() {
      let labels = null
      let datasets = []

      for (const [key, value] of Object.entries(this.data)) {
        if (!labels) {
          labels = value.timestamps
        }

        datasets.push({data: value.values, label: key})
      }

      return {
        labels: labels,
        datasets: datasets
      }
    }
  },
}
</script>
