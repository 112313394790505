import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import './scss/styles.scss'
import axios from "axios";

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:9000'
}

import {Chart, registerables} from 'chart.js'
Chart.register(...registerables)

import {SankeyController, Flow} from 'chartjs-chart-sankey';
Chart.register(SankeyController, Flow);

createApp(App).use(store).use(router).mount('#app')
