<template>
  <div class="row" v-if="endpoints">
    <div class="col-12">
      <h5>Select Endpoint</h5>
    </div>
    <EndpointPicker :endpoints="endpoints" v-model="endpoint" @firstEndpoint="generateChart" :series="this.series"/>
  </div>
  <template v-if="endpoint">
    <div class="row mt-4" v-if="errors">
      <div v-for="error in errors" :key="error" class="alert alert-danger">
        {{ error }}
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <h5>Select Parameters</h5>
      </div>
      <div class="col-3" v-for="(parameter, index) in parametersSchemas" :key="index">
        <EndpointParameter :parameter="parameter" v-model="parameters[parameter.name]" :series="series"/>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <button class="btn btn-cf" @click="generateChart"><i class="bi bi-bar-chart"></i> Generate</button>
      </div>

      <div class="col me-0 ms-auto text-end">
        <button class="btn btn-primary me-1" @click="series++"><i class="bi bi-plus"></i> Series</button>
        <button class="btn btn-primary" @click="series--"><i class="bi bi-dash"></i> Series</button>
      </div>
    </div>

    <template v-if="data || loadingData">
      <hr>
      <div class="row mt-4" v-if="loadingData">
        <div class="col-12 text-center fs-2 my-5">
          <div class="lds-dual-ring"></div>
        </div>
      </div>

      <div class="row mt-4" v-if="data">
        <div class="col-12">
          <h3 class="text-center fs-4 mb-3 text-capitalize">{{ endpoint.chart_title }}</h3>
          <chart-wrapper :endpoint="endpoint" :data="data"/>
        </div>
      </div>
    </template>
  </template>
</template>

<script>
import axios from "axios";
import EndpointParameter from "@/components/EndpointParameter.vue";
import EndpointPicker from "@/components/EndpointPicker.vue";
import ChartWrapper from "@/components/ChartWrapper.vue";

export default {
  name: 'ExplorerView',
  components: {ChartWrapper, EndpointPicker, EndpointParameter},
  data: function () {
    return {
      endpoints: null,
      endpoint: null,
      parameters: {},
      data: null,
      series: 1,
      loadingData: false,
      errors: []
    }
  },
  computed: {
    parametersSchemas() {
      return this.endpoint.parameters.filter(val => {
        return val.name !== 'debug' && val.name !== 'format'
      })
    }
  },
  watch: {
    endpoint(newValue, oldValue) {
      if (newValue === null) {
        this.data = null
        this.parameters = {}
        this.errors = []
      } else if (oldValue !== null){
        this.parameters = {}
        this.errors = []
      }
    },
    series(newValue) {
      this.$router.push({
        name: 'home',
        query: {endpoint: this.$route.query.endpoint, series: newValue, parameters: this.$route.query.parameters}
      })
    },
    parameters: {
      handler: function (newValue) {
        if (newValue === {} || Object.keys(newValue).length === 0 || !newValue) {
          this.$router.push({
            name: 'home',
            query: {endpoint: this.$route.query.endpoint, series: this.series}
          })
        } else {
          this.$router.push({
            name: 'home',
            query: {endpoint: this.$route.query.endpoint, series: this.series, parameters: JSON.stringify(newValue)}
          })
        }
      },
      deep: true
    },
  },
  methods: {
    getParameterByName(name) {
      for (const param of Object.values(this.endpoint.parameters)) {
        if (param.name === name) {
          return param
        }
      }

      throw new Error(`Parameter ${name} not found`)
    },
    parseArgument(args, key, value) {
      if (value) {
        if (key === 'dateStart' || key === 'dateEnd') {
          value = `${value}:00.0Z`
        }

        args.push(`${key}=${value}`)
      }
    },
    async generateChart() {
      if (!this.endpoint) {
        return
      }
      let url = `${this.endpoint.url}?`

      const args = []
      for (const [key, value] of Object.entries(this.parameters)) {
        const field = this.getParameterByName(key)

        if (field.in === 'query') {

          if (typeof value === 'object') {
            const tmpArg = []
            for (const [index, val] of Object.entries(value)) {
              if (index > 0 && tmpArg[index - 1] === undefined) {
                tmpArg.push(`${key}=`)
              }

              this.parseArgument(tmpArg, key, val)
            }

            args.push(tmpArg.join('&'))
          } else {
            this.parseArgument(args, key, value)
          }

        } else if (field.in === 'path') {
          url = url.replace(`{${key}}`, value)
        }

      }

      url += args.join('&')
      // console.log(`URL: ${url}`)

      let resp
      try {
        this.loadingData = true
        this.errors = []
        resp = await axios.get(url)
      } catch (e) {
        this.errors = e.response.data.errors.map(val => {
          if (val.path) {
            return `${val.path[val.path.length - 1]}: ${val.message}`
          }
          return val.message
        })
        this.data = null
        this.loadingData = false
        return
      }

      if (resp.data.result.meta)
        delete resp.data.result.meta

      this.data = resp.data.result
      this.loadingData = false
    },
    async fetchSchema() {
      const resp = await axios.get('/openapi.json')

      const endpoints = {}
      for (const [url, methods] of Object.entries(resp.data.paths)) {
        for (const endpoint of Object.values(methods)) {
          if (endpoint.deprecated) {
            continue
          }

          let chartType = "table"

          if (url.endsWith('/top/attacks')) {
            chartType = 'pewpew'
          } else if (url.includes('timeseries_groups')) {
            chartType = 'timeseries_groups'
          } else if (url.includes('timeseries')) {
            chartType = 'timeseries'
          } else if (url.includes('summary')) {
            chartType = 'summary'
          } else if (url.includes('top')) {
            chartType = 'top'
          }

          endpoints[url.replace('/api/v1/', '')] = {
            ...endpoint,
            url: url,
            chart_title: url.replace('/api/v1/', '').replaceAll('/', ' ')
                .replaceAll(/{(\w+)}/g, '').replaceAll('_', ' ').trim(),
            chartType: chartType,
          }
        }
      }

      this.endpoints = endpoints
    }
  },
  async created() {
    if (this.$route.query.series) {
      this.series = parseInt(this.$route.query.series)
    }
    if (this.$route.query.parameters) {
      this.parameters = JSON.parse(this.$route.query.parameters)
    }

    await this.$nextTick()
    await this.fetchSchema()
  }
}
</script>
