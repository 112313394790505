<template>
  <div class="row">
    <div class="col-12" v-for="(value, key) in data" :key="key">
      <table class="table table-hover" v-if="Array.isArray(value) && value.length > 0">
        <thead>
        <tr>
          <th scope="col" v-for="(val, col) in value[0]" :key="col" v-text="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in value" :key="index">
          <td v-for="(val, col) in row" :key="col" v-text="val"></td>
        </tr>
        </tbody>
      </table>
      <pre v-else v-text="JSON.stringify(value, null, 2)"></pre>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TableChart',
  props: ['data'],
  data() {
    return {}
  },
  created() {
    console.log(this.data)
  }
}
</script>
